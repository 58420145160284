<template>
  <BaseListPage locale-section="pages.periods" route="period">
    <PeriodList />
  </BaseListPage>
</template>

<script>
export default {
  name: "PeriodsPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    PeriodList: () => import("./PeriodList")
  }
};
</script>
